
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$flow-v2-bg: white;
@if variable-exists(flow-v2-bg-header-overrided) {
  $flow-v2-bg: $flow-v2-bg-header-overrided;
}
.warpper {
  display: flex;
  justify-content: stretch;
  z-index: 33;
  background: $flow-v2-bg;
  height: var(--flow-v2-header-size) !important;
  padding: 0.75rem 1.5rem; //24

  .img-container {
    display: flex;
    flex: 1;
    max-width: 75%;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .img-container-one-doc {
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-left: -0.3rem;
    max-width: 75%;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .language-chooser {
    display: flex;
    height: 100%;
    margin-left: 1.5rem; //24
    align-items: center;
    font-size: 1rem; //16
    cursor: pointer;

    img {
      height: 1.125rem; //18
    }
  }
}

.isDark {
  background: #000 !important;
}

@media (max-width: 760px) {
  .warpper {
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
