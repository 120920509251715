
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.warpper {
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  background-color: var(--primary);
  display: block;
  color: white;
  overflow: hidden;
  z-index: 33;
  box-shadow: 0px 5px 15px 0px rgba(darken($c-primary, 10%), 0.15);

  position: absolute;

  .title {
    font-size: 17px;
    font-weight: 400;
    margin-left: 24px;
    margin-top: 18px;
    text-align: left;
  }

  .backButton {
    position: absolute;
    border: 0;
    background-image: url(_img('icons/svg/back-btn.svg'));
    width: 34px;
    height: 57px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 9px 14px;
    top: 0;
    left: 15px;
    background-position-y: center;
    background-position-x: 15px;
    margin-left: -15px;
  }

  .langButton-white {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_white.svg'));
  }

  .langButton-black {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_black.svg'));
  }

  .langButton {
    position: absolute;
    border: 0;
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 20px 20px;
    top: 0;
    right: 0;
    background-position-y: center;
    background-position-x: center;
    margin-left: -15px;
  }
}

@media (max-width: 760px) {
  .warpper {
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
