
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  text-align: center;

  .dontHave {
    margin-top: 40px;
    max-width: 230px;
    margin: 50px auto 0;

    img {
      display: block;
      margin: 25px auto;
    }

    span {
      font-size: 13px;
    }
  }
}

@media (max-height: 595px) {
  .wrapper {
    margin-top: -20px;

    .dontHave {
      margin-top: 10px;
    }
  }
}
