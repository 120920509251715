
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .content {
    height: calc(100% - 108px);
    overflow-y: auto;
    margin-bottom: 120px;
  }
  h3 {
    font-weight: 600;
  }
  p,
  ul li {
    font-weight: 300;
  }

  ul {
    margin-bottom: 25px;
    margin-top: 20px !important;
  }
}
