$c-primary: #5f259f;
$c-secandary: #0096e6;
$dots: false;
$version: 3.1;

$loading-bg-overrided: white;
$progress-bar-overided: #000000;
$dot-overrided: white;

@font-face {
  font-family: 'AvenirNext';
  src: url('#{$project-font-path}AvenirNext-Medium.otf');
  font-weight: 400;
  font-style: normal;
}

$base-font-family: 'AvenirNext', sans-serif;
$font-family-heading: 'AvenirNext', sans-serif;

.b-logo {
  max-width: 80px !important;
}
