
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$btn-text: white;
$btn-text-secondary: var(--secondary);
@if variable-exists(btn-text-overrided) {
  $btn-text: $btn-text-overrided;
}
@if variable-exists(btn-text-secondary-overrided) {
  $btn-text-secondary: $btn-text-secondary-overrided;
}
.btn {
  border: 1px solid var(--primary);
  background: var(--primary);
  display: inline-block;
  color: $btn-text;
  height: 48px;
  min-height: 48px;
  font-family: var(--base-font-family) !important;
  border-radius: 4px;
  padding: 2px 20px 4px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;

  &.large {
    height: 60px;
    min-height: 60px;
    padding-top: 0px;
  }

  &.full {
    width: 100%;
  }

  &.shadow {
    box-shadow: 0px 5px 20.16px 3.84px lighten(#000, 87%);
  }

  &.secandary {
    background: var(--secondary);
    border-color: var(--secondary);
  }

  &.transparent {
    background: transparent;
    border-color: transparent;
    color: black;
  }

  &.outline {
    background: none;
    color: $btn-text-secondary;
    border-color: var(--secondary);
    box-shadow: none;
  }

  &.outline.secandary {
    color: var(--secondary);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: none;
    opacity: 0.9;
  }

  &[disabled] {
    opacity: 0.7;
  }

  .link-inside-button {
    background: red;
  }
}
