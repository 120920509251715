
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/spark/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  padding-left: 25px;

  label {
    display: block;
    position: relative;
    align-items: center;
    min-height: 28px;
    padding-left: 7px;
    font-size: 14px;
    line-height: 20px;
    //color: $c-primary;
  }

  label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-left: -25px;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  label::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 11px;
    margin-left: -25px;
    top: 5px;
    left: 6px;
    margin-top: -3px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before {
    border: 1px solid var(--secondary);
  }
  input:checked + label::before,
  input:checked + label::after {
    background: var(--secondary);
    display: block;
  }
}
